import service from './network.js'
const BASE_URL = process.env.VUE_APP_BASE_API

/**
 * 项目园区接口
 */

// 园区
export function getProjList(params) {
    return service({
        method: 'get',
        url: '/app/cre/project/page',
        params
    })
}
//园区详情
export function getProjectDetail(id) {
    return service({
        method: 'get',
        url: '/app/cre/project/ne?id=' + id
    })
}

export function getProjPoster(id) {
    return BASE_URL + `/app/cre/project/image?id=${id}`
}

// 楼宇
export function getBuildingList(params) {
    return service({
        method: 'get',
        url: '/app/cre/building/page',
        params
    })
}
//楼宇详情
export function getBuildingDetail(id) {
    return service({
        method: 'get',
        url: '/app/cre/building/ne?id=' + id
    })
}

export function getBuildingPoster(id) {
    return BASE_URL + `/app/cre/building/image?id=${id}`
}


export function getNearby(params) {
    return service({
        method: 'get',
        url: '/app/facility/nearBy',
        params
    })
}


/**
 * 字典等接口
 */

export function getSearchDits(){
    return service({
        method: 'get',
        url: '/app/dict/lists',
        params: {
            keys: 'search.rentRange,search.radius,search.facilities,search.landArea,resCreProject.industryOrientation,resLandBasic.purpose'
        }
    })
}
export function getDict(params) {
    return service({
        method: 'get',
        url: '/app/system/dict',
        params
    })
}
export function getDicts(params) {
    return service({
        method: 'get',
        url: '/app/system/dicts',
        params
    })
}
// 获取单个图文
export function getrichText(params){
    return service({
        method: 'get',
        url: '/app/system/richtext/one',
        params
    })
}
// 获取单个图文详情
export function getrichTextDetail(id){
    return service({
        method: 'get',
        url: '/app/system/richtext?id=' + id,
    })
}
// 获取单个图文详情
export function getrichTextUrl(id){
    return service({
        method: 'get',
        url: '/app/system/attachUrl/' + id,
    })
}
// 获取图文列表
export function getrichTexts(params){
    return service({
        method: 'get',
        url: '/app/system/richtexts',
        params
    })
}

export function searchLandArea(){
    return service({
        method: 'get',
        url: '/app/dict/list',
        params: {
            key: 'search.landArea'
        }
    })
}

// 功能区
export function getResourceArea() {
    return service({
        method: 'get',
        url: '/app/dict/list',
        params: {
            key: 'resource.areaName'
        }
    })
}

export const getAttachUrl = (id) => {
    return BASE_URL + `/app/system/attach/${id}`
}

export const getAttaFinalUrl = async (id) => {
    return await getrichTextUrl(id)
}