import axios from 'axios'
import { Notification } from 'element-ui'
const qs = require('qs');
// import { getLocalStorage } from '@/tools/tools'


const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 5000 // request timeout
})
/// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent
    // if (config.method === 'post') {
    //   config.data = qs.stringify(config.data, { arrayFormat: 'brackets' })
    // }
    // const token = getLocalStorage('token')
    // if (token) {
    //   // let each request carry token
    //   config.headers['Authorization'] = 'Bearer ' + token
    // }
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * success 操作状态
   * message 提示信息
   * result 结果数据
   */
  response => {
    const { data } = response
    if (data.code === 200) {
      return data
    }
    Notification({
      title: '提示',
      message: data.message,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(data)
  },
  error => {
    console.log(error);
    // const { status } = error.response

    // // 重新登陆
    // if (status === 401 && !store.getters.token) {
    //   location.reload()
    // }


    // Message({
    //   message: '系统出错，请联系系统管理员！',
    //   type: 'error',
    //   duration: 5 * 1000
    // })
    return Promise.reject(error)
  }
)

export default service